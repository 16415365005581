import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer
} from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
        <div>
        <h2 id="securitymeasures">Security Measures</h2>
        <p><em>Effective Date: November 24, 2019</em></p>
        <p><strong>Capitalized terms that are not defined in these Security Measures have the meanings set forth in the <a href="/terms-of-service">Terms of Service</a> or the <a href="/data-processing-addendum">Data Processing Addendum</a>.</strong></p>
        <p><strong>Security Overview</strong></p>
        <p>AirPage has a dedicated security team that guides the implementation of controls, processes, and procedures governing the security of AirPage and its customers. The AirPage security team is responsible for developing, implementing and maintaining an information security program that reflects the following principles:</p>
        <ul>
            <li>Align security activities with AirPage’s strategies and support AirPage’s objectives.</li>
            <li>Leverage security to facilitate confidentiality, integrity, and availability of data and assets.</li>
            <li>Utilize AirPage’s security resources efficiently and effectively.</li>
            <li>Utilize monitoring and metrics to facilitate adequate performance of security related activities.</li>
            <li>Manage security utilizing a risk based approach.</li>
            <li>Implement measures designed to manage risks and potential impacts to an acceptable level.</li>
            <li>Leverage industry security frameworks where relevant and applicable.</li>
            <li>Leverage compliance/assurance processes as necessary.</li>
            <li>Analyze identified or potential threats to AirPage and its customers, provide reasonable remediation recommendations, and communicate results as appropriate.</li>
        </ul>
        <p><strong>Data Center Security, Availability, and Disaster Recovery</strong></p>
        <ul>
            <li>AirPage leverages leading data center providers to house our physical infrastructure.</li>
            <li>Our data center providers utilize an array of security equipment, techniques and procedures designed to control, monitor, and record access to the facilities.</li>
            <li>We have implemented solutions designed to protect against and mitigate effects of DDoS attacks.</li>
            <li>We have dedicated teams located in multiple geographies to support our platform and supporting infrastructure.</li>
            <li>AirPage maintains geographically separate data centers to facilitate infrastructure and service availability and continuity.</li>
            <li>AirPage has a formally documented disaster recovery (failover) plan which is tested at least annually. Results of testing are documented and maintained.</li>
        </ul>
        <p><strong>Application Level Security</strong></p>
        <ul>
            <li>AirPage hashes passwords for user accounts and provides SSL for customers.</li>
            <li>AirPage utilizes Web Application Firewall (WAF) technology.</li>
            <li>Regular pen testing is performed on the AirPage platform, the results of which are analyzed and remediated (as appropriate) by our engineering and security teams.</li>
            <li>Customers are provided the ability to customize website permissioning.</li>
        </ul>
        <p><strong>Incident Response</strong></p>
        <ul>
            <li>In the event of an issue related to the security of the AirPage platform, the AirPage security team follows a formal incident response process.</li>
            <li>We analyze identified or potential threats to AirPage and its customers, provide reasonable remediation recommendations, and communicate results as appropriate.</li>
        </ul>
        <p><strong>AirPage Building and Network Access</strong></p>
        <ul>
            <li>Physical access to AirPage offices and access to the AirPage internal network is restricted and monitored.</li>
        </ul>
        <p><strong>Systems Access Control</strong></p>
        <ul>
            <li>Access to AirPage systems is limited to appropriate personnel.</li>
            <li>AirPage subscribes to the principle of least privilege (e.g., employees, system accounts, vendors, etc. are provided with the least amount of access for their job function).</li>
            <li>AirPage leverages multifactor authentication.</li>
        </ul>
        <p><strong>Security Risk Management</strong></p>
        <p>Threat intelligence and risk assessment are key components of AirPage’s information security program. Awareness and understanding of potential (and actual) threats guides the selection and implementation of appropriate security controls to mitigate risk. Potential security threats are identified, and assessed for severity and exploitability prior to being classified as risks. If risk mitigation is required, the security team works with relevant stakeholders and system owners to remediate. The remediation efforts are tested to confirm the new measures/controls have achieved their intended purpose.</p>
        </div>
    </LegalContainer>
  </MainLayout>
);
